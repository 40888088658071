.lettre-container {
  position:relative;
  width:100%;
  padding-bottom:222%;
  .lettre-container-inner {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-color: #000;
    overflow:hidden;
    cursor:grab;
    background-color:red;
  }
  .player-tool-bar {
    position: absolute;
    left:0;
    top: 0;
    z-index:10;
    color:#FFF;
  }
}
