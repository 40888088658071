.portrait-player-container {
  position: sticky;
  top: 30px;;
  height:800px;
}
.player-tool-bar {
  position: sticky;
  top: 30px;
  z-index:10;
  color:#FFF;
}
