.hook-label {
  color:rgba(0, 0, 0, 0.6);
}
.cell-hook {
  width:500px;
}
.cell-track {
  width:150px;
}
.action-icon {
  font-size:5px;
}
.son-cell {
  position:relative;
  padding-bottom:10px;
}
.son-size {
  display:flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  height:1em;
  top:1.7em;
  left:0;
  font-size:0.8em;
  background:#FFF;
  padding:2px;
  border-radius:2px;
  box-shadow: 0 0 5px #0002;
  line-height:1em;
  .action-icon {
    font-size:1.5em;
    line-height: 0.5;
    width:0.8em;
    height:0.8em;
  }
}
.total-size{
  width:100%;
  height:100%;
  font-size:24px;
  display:flex;
  justify-content: center;
  align-items: center;
}
