.input-markdown.black {
  .MuiPaper-root {
    background-color: #000;
    color:#FFF;
    img[alt='icon'] {
      width:50px;
      margin:5px;
      vertical-align: middle;
    }
    img[alt='image'] {
      max-width: calc(100% - 10px);
      margin:5px;
      vertical-align: middle;
    }
  }
}
